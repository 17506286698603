import React from 'react'
import { useWindowDimension } from "../../hooks/useWindowDimension"
import NormalHeader from "./normalHeader"
import ResponsiveHeader from './responsiveHeader'

const Header = ({ page }) => {
    const { width } = useWindowDimension()
    if(960 < width ) return <NormalHeader page={page} />
    else return <ResponsiveHeader page={page} />
}

export default Header
