/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { Link } from "gatsby"
import Logo from "../../../static/images/logo/logo.svg"

const ResponsiveMenuButton = ({ openResMenu, setOpenResMenu, page }) => {
    return (
        <a onClick={() => setOpenResMenu(!openResMenu)} className={page !== undefined ? "responive-button" : "small-responive-button"}>
            <div className={openResMenu ? "rotate1" : ""}></div>
            <div className={openResMenu ? "rotate2" : ""}></div>
            <div className={openResMenu ? "rotate3" : ""}></div>
        </a>
    )
}

const ResponsiveMenu = ({ openResMenu/* , page  */}) => {
    return (
        <>
            <ul className={openResMenu ? "responsivemenu-ul-open" : "responsivemenu-ul-close"}>
                <li className="responsivemenu-li"><Link to="/?r=res"><img src={Logo} alt="Logo"/></Link></li>
                <li className="responsivemenu-li"><Link to="/business/?r=res">このサイトについて</Link></li>
       {/*          <li className="responsivemenu-li"><Link to="/service/?r=res">サービス</Link></li> */}
                {/* <li className="responsivemenu-li"><Link to="/works/?r=res">制作実績</Link></li> */}
                <li className="responsivemenu-li"><Link to="/blog/?r=res">ブログ</Link></li>
               {/*  <li className="responsivemenu-li"><Link to="/sdgs/?r=res">SDGsの取り組み</Link></li> */}
             {/*    <li className="responsivemenu-li"><Link to="/download/?r=res">無料ダウンロード</Link></li> */}
                <li className="responsivemenu-li"><Link to="/contact/?r=res">コンタクト</Link></li>
            </ul>
        </>
    )
}

export { ResponsiveMenu, ResponsiveMenuButton }