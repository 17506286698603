import React from 'react'
import { Link } from "gatsby"

const Menu = () => {
    return (
        <ul className="menu-ul">
            <li><Link to="/business">About</Link></li>
           {/*  <li><Link to="/service">Service</Link></li> */}
           {/*  <li><Link to="/works">Works</Link></li> */}
            <li><Link to="/blog">Blog</Link></li>
        </ul>
    )
}

export default Menu