import React from 'react'
import Header from "../components/header"
import Seo from "../components/seo"
import RegisterNewsLetterBody from '../components/registerNewsLetter'

const backendUrl = "https://us-central1-nov182021.cloudfunctions.net/server/present-for-readers"

const PresentForReaders = () => {
    return (
        <>
            <Header/>
            <Seo title="読者限定無料プレゼントページ" 
                 description="読者限定無料プレゼントページ"
            />
            <h3 style={{marginTop: "150px", textAlign: "center"}}>読者限定無料プレゼント</h3>
            <RegisterNewsLetterBody 
                backendUrl={backendUrl}
                text={<p>（読者限定）React学習で知っておきたいことをまとめた資料をプレゼント</p>}
            />
        </>
    )
}

export default PresentForReaders