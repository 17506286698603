import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import { ResponsiveMenu, ResponsiveMenuButton } from "./responsiveMenu"
import Logo from "../../../static/images/logo/logo.svg"

const ResponsiveHeader = ({ page }) => {
    const [openResMenu, setOpenResMenu] = useState(false)
    useEffect(()=>{
            if(openResMenu) document.body.style.overflow = 'hidden'
            return ()=> document.body.style.overflow = 'unset'
    }, [openResMenu])

    return (
        <header>
            <ResponsiveMenuButton openResMenu={openResMenu} setOpenResMenu={setOpenResMenu} page={page}/>
            <ResponsiveMenu openResMenu={openResMenu} page={page}/>
            <div className={page !== undefined ? "header" : "small-responsive-header"} >
                <div className="show-white">
                    <div className="sticky">
                        <div>
                            <Link to="/" title="monotein"><img src={Logo} alt="Logo"/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default ResponsiveHeader
